@import "../../scss/variables";
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
/* No Js */
/*
 *  Owl Carousel - Animate Plugin
 */
@keyframes "fadeOut" {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
/*
 * 	Owl Carousel - Lazy Load Plugin
 */
/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden;
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
	}
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	.owl-wrapper {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
	}
	.owl-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		img {
			display: block;
		}
		.owl-lazy {
			opacity: 0;
			transition: opacity 400ms ease;
			&:not([src]) {
				max-height: 0;
			}
		}
		.owl-lazy[src^=""] {
			max-height: 0;
		}
		img.owl-lazy {
			transform-style: preserve-3d;
		}
	}
	.owl-nav.disabled {
		display: none;
	}
	.owl-dots.disabled {
		display: none;
	}
	.owl-nav {
		.owl-prev {
			cursor: pointer;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.owl-next {
			cursor: pointer;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		button.owl-prev {
			opacity: 7;
			color: inherit;
			border: none;
			padding: 0 !important;
			font: inherit;
		}
		button.owl-next {
			opacity: 7;
			color: inherit;
			border: none;
			padding: 0 !important;
			font: inherit;
		}
	}
	.owl-dot {
		cursor: pointer;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	button.owl-dot {
		opacity: 7;
		color: inherit;
		border: none;
		padding: 0 !important;
		font: inherit;
	}
	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}
	.owl-animated-in {
		z-index: 0;
	}
	.owl-animated-out {
		z-index: 1;
	}
	.fadeOut {
		animation-name: fadeOut;
	}
	.owl-video-wrapper {
		position: relative;
		height: 100%;
		background: #000;
	}
	.owl-video-play-icon {
		position: absolute;
		height: 80px;
		width: 80px;
		left: 50%;
		top: 50%;
		margin-left: -40px;
		margin-top: -40px;
		background: url("owl.video.play.png") no-repeat;
		cursor: pointer;
		z-index: 1;
		-webkit-backface-visibility: hidden;
		transition: transform 100ms ease;
		&:hover {
			-ms-transform: scale(1.3, 1.3);
			transform: scale(1.3, 1.3);
		}
	}
	.owl-video-playing {
		.owl-video-tn {
			display: none;
		}
		.owl-video-play-icon {
			display: none;
		}
	}
	.owl-video-tn {
		opacity: 0;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: opacity 400ms ease;
	}
	.owl-video-frame {
		position: relative;
		z-index: 1;
		height: 100%;
		width: 100%;
	}
}
.owl-carousel.owl-loaded {
	display: block;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel.owl-refresh {
	.owl-item {
		visibility: hidden;
	}
}
.owl-carousel.owl-drag {
	.owl-item {
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}
.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
	.owl-item {
		float: right;
	}
}
.no-js {
	.owl-carousel {
		display: block;
	}
}
.owl-height {
	transition: height 500ms ease-in-out;
}
.owl-controls {
	.owl-page {
		width: 65px;
		height: 65px;
		border-radius: 50%;
		border: 4px solid #d9d6e0;
		background: url("../../images/users/female/6.jpg") no-repeat;
		background-size: cover;
		transition: all 0.3s ease-in-out 0s;
		display: inline-block;
		zoom: 1;
		&:nth-child(2) {
			background: url("../../images/users/male/13.jpg") no-repeat;
			background-size: cover;
		}
		&:nth-child(3) {
			background: url("../../images/users/female/15.jpg") no-repeat;
			background-size: cover;
		}
	}
	margin-top: 40px;
	text-align: center;
}
.owl-controls.clickable {
	.owl-page {
		&:hover {
			opacity: 0.5;
		}
	}
}
.testimonia {
	.post {
		display: block;
		font-size: 15px;
		color: rgba(255, 255, 255, 0.7);
	}
	.title {
		font-size: 24px;
		font-weight: bold;
		color: #fff;
		text-transform: capitalize;
		margin: 0;
		margin-bottom: 1px;
	}
	.rating-stars {
		.rating-stars-container {
			.rating-star.sm {
				display: inline-block;
				font-size: 14px;
				color: rgba(255, 255, 255, 0.3);
				cursor: pointer;
				padding: 1px;
				padding-right: 5px;
				padding-left: 5px;
			}
			.rating-star.is--active {
				color: #f1c40f !important;
			}
		}
	}
}
.rating-stars {
	.rating-stars-container {
		.rating-star.is--hover {
			color: #f1c40f !important;
		}
	}
}
